<template>
  <div class="mineTask-box">
    <div class="title-box">
      <div v-for="(item, index) in titleList" :key="'task-'+index"
        @click="activeTab = item.type"
        :class="['title', activeTab === item.type ? 'checked' : '']">
        {{ item.label }}({{ item.count }})
      </div>
    </div>
    <div v-if="dataList && dataList.length" v-infinite-scroll="loadMore" class="list">
      <div v-for="(item, index) in dataList" :key="'data-'+index" @click="toDetail(item)" class="item">
        <div class="data">
          <span class="company">{{ item.customerMsg ? item.customerMsg.name : '--' }}</span>
          <div v-if="activeTab === 'wait'" class="orange">待接单</div>
          <div v-else :class="getStatusColor(item.statusMsg.label)">{{ item.statusMsg ? item.statusMsg.label : '--' }}</div>
          <span class="data-col">指派人：{{ item.dispatchMsg ? item.dispatchMsg.name : '--' }}</span>
          <span class="data-col">指派时间：{{ item.dispatchTime ? formatDate(item.dispatchTime, 'YYYY-MM-DD HH:mm:ss') : '--' }}</span>
          <span class="data-col">创建时间：{{ item.createTime ? formatDate(item.createTime, 'YYYY-MM-DD HH:mm:ss') : '--' }}</span>
        </div>
        <img :src="require('@/assets/imgs/workbench/icon_to.svg')"  class="toDetail"/>
      </div>
    </div>
    <Empty v-else />
  </div>
</template>
<script>
import * as Moment from 'dayjs';
import OrderApi from '@/api/order';
import Empty from '@/components/empty.vue';
export default {
  components: {
    Empty,
  },
  watch: {
    activeTab () {
      this.init();
    },
  },
  data () {
    return {
      activeTab: 'wait',
      titleList: [
        {
          type: 'wait',
          label: '我的待办',
          count: 0,
        },
        {
          type: 'created',
          label: '我创建的',
          count: 0,
        },
      ],
      pageNum: 1,
      pageSize: 10,
      loading: false,
      dataList: [],
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    formatDate (text, format = 'YYYY-MM-DD') {
      return text ? Moment(text * 1).format(format) : '/';
    },
    getStatusColor (label) {
      if (label.indexOf('待') !== -1) {
        return 'orange';
      }
      if (label.indexOf('中') !== -1) {
        return 'blue';
      }
      if (label.indexOf('已取消') !== -1) {
        return 'grey';
      } else if (label.indexOf('已') !== -1) {
        return 'green';
      }
    },
    toDetail (item) {
      this.$router.push({
        name: this.isSynergy ? 'EXTERNAL_ORDER_EDIT' : 'ORDER_EDIT',
        query: {
          id: item.id,
          templateId: item?.typeMsg?.id,
        },
      });
    },
    async queryWorkOrderCount () {
      const data = await OrderApi.statusStastic();
      this.titleList[0].count = data.body.pendingOrders || 0;
      this.titleList[1].count = data.body.meCreated || 0;
    },
    loadMore () {
      if (this.loading) return;
      this.loading = true;
      this.pageNum += 1;
      this.queryList();
    },
    async queryList () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        evalStatusMsg: null,
        orderBy: [
          {
            orderByProperty: 'createTime',
            orderByType: 'DESC',
          },
        ],
      };

      if (this.activeTab === 'wait') {
        params.independentStatusMsg = {
          value: 'PENDING_ORDERS',
          label: '待接单',
          countField: 'pendingOrders',
        };
      }

      if (this.activeTab === 'created') {
        const { employeeMsg } = JSON.parse(this.$local.get('userInfo'));
        params.createMsg = {
          id: employeeMsg.id,
          name: employeeMsg.name,
          departmentId: employeeMsg.departmentId,
        };
      }
      const data = await OrderApi.getProductList(params);
      this.loading = false;
      if (this.pageNum === 1 && data.body.records && data.body.records.length) {
        this.dataList = data.body.records || [];
      }
      if (this.pageNum > 1 && data.body.records && data.body.records.length) {
        this.dataList = this.dataList.concat(data.body.records);
      }
    },
    init () {
      this.pageNum = 1;
      this.dataList = [];
      this.queryWorkOrderCount();
      this.queryList();
    },
  },
};
</script>
<style lang="scss" scoped>
.mineTask-box {
  font-size: 14px;
  color: #1F2733;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 0 20px 5px;
  margin-bottom: 15px;
  .title-box {
    display: inline-block;
    .title {
      display: inline-block;
      font-weight: bold;
      padding: 20px 20px 20px 0;
      font-size: 16px;
      cursor: pointer;
    }
    .checked {
      color: #237FFA;
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    height: 250px;
    overflow-y: scroll;
    .item {
      margin-bottom: 10px;
      background-color: #F5F7FA;
      border-left: 2px solid #237FFA;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      cursor: pointer;
      .data {
        padding-left: 15px;
        display: inline-flex;
        align-items: center;
        .company {
          font-weight: bold;
        }
        .orange, .red, .blue, .green, .grey {
          font-size: 12px;
          font-weight: 400;
          padding: 1px 5px;
          margin-left: 10px;
          margin-right: 10px;
          border-radius: 4px;
          flex-shrink: 0;
        }
        .orange {
          color: #FCCD5C;
          background-color: #FEFAE0;
        }
        .red {
          color: #FB4949;
          background-color: #fdf2f2;
        }
        .blue {
          color: #77B1FB;
          background-color: #E2F3FE;
        }
        .green {
          color: #6CDE9C;
          background-color: #E5FEEB;
        }
        .grey {
          color: #5F666E;
          background-color: #F2F4F7;
        }
        .data-col {
          padding-right: 15px;
          font-size: 12px;
          font-weight: 400;
          color: #929AA6;
          flex-shrink: 0;
        }
      }
      .toDetail {
        margin-right: 15px;
        width: 14px;
        height: 14px;
      }
    }
  }
}
</style>
